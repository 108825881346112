<template>
  <button v-if="!playFlag" @click="onPlay()">Play</button>
  <button v-else @click="onPause()">Pause</button>
</template>

<script>
import SoundReactor from "../classes/SoundReactor";

export default {
  name: "AudioButton",
  data() {
    return {
      initFlag: false,
      playFlag: false
    };
  },
  methods: {
    onPlay() {
      if (!this.initFlag) {
        this.initFlag = true;
        SoundReactor.init();
      }
      SoundReactor.play();
      this.playFlag = true;
    },
    onPause() {
      SoundReactor.pause();
      this.playFlag = false;
    }
  }
};
</script>

<style scoped lang="stylus">
.titleScreen {
    position: absolute;
    top: 0;
    left: 0;
    color: white;
    padding: 100px;
    pointer-events: none;
    z-index: 999;
}

button {
    margin: 100px;
    position: absolute;
    color: white;
    cursor: pointer;
    background: transparent;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100px;
    height: 50px;
    position: absolute;
    bottom: 0;
    right: 0;
    border: 2px solid #f7eb1f;
    transition: all 0.3s;

    &:hover {
      background: #f7eb1f;
      color: #000000;
    }
}
</style>
